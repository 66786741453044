import Vue from 'vue';
import store from './store';
import App from './App.vue';
import i18n from './i18n'
import BootstrapVue from 'bootstrap-vue';
import "@/assets/bootstrap.scss";
import "vue-multiselect/dist/vue-multiselect.min.css";
import ShuttleVue from 'shuttle-vue';
import Vuelidate from 'vuelidate';
import Multiselect from 'vue-multiselect'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleUp, faCircleNotch, faExternalLinkAlt, faEye, faEyeSlash, faKey, faMoon, faHourglass, faSignOutAlt, faUser, faPlusSquare, faShieldAlt, faSyncAlt, faSun, faTrashAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './router'
import access from './access';
import api from './api';

library.add(faAngleDown, faAngleUp, faCircleNotch, faExternalLinkAlt, faEye, faEyeSlash, faKey, faMoon, faHourglass, faSignOutAlt, faUser, faPlusSquare, faShieldAlt, faSyncAlt, faSun, faTrashAlt, faUserCircle);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('multiselect', Multiselect);

Vue.use(BootstrapVue);
Vue.use(ShuttleVue);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

Vue.prototype.$api = api;
Vue.prototype.$access = access;

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');

access.initialize()
  .then(() => {
    switch (access.loginStatus) {
      case 'logged-in':
        {
          store.commit('AUTHENTICATED');
          break;
        }
      default: {
        if (!router.currentRoute.name) {
          router.push({ path: "/login" });
        }
      }
    }
  })
  .catch(function () {
    store.dispatch('addAlert', {
      message: i18n.t('exceptions.access-failure'),
      type: 'danger',
      expire: false
    });
  })
  .finally(function () {
    store.commit('STARTED');
  });
