<template>
  <div>
    <b-navbar toggleable="lg" fixed="top">
      <b-navbar-brand href="#">
        <img src="@/assets/logo-small.png" alt="Fidelior logo" />
      </b-navbar-brand>
      <b-navbar-toggle
        target="nav-text-collapse"
        v-if="authenticated"
      ></b-navbar-toggle>
      <b-collapse id="nav-text-collapse" is-nav v-if="authenticated">
        <b-navbar-nav>
          <b-nav-item to="/search">{{ $t("search-titles") }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item to="/report">{{ $t("submit-references") }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item to="/lists">{{ $t("list-information") }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item to="/holdings">{{ $t("submit-holding") }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto" right>
          <b-nav-item to="/password">
            <font-awesome-icon icon="key" />
          </b-nav-item>
          <b-nav-item v-on:click="logout">
            <font-awesome-icon icon="sign-out-alt" />
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <b-navbar-nav class="ml-auto" right>
        <b-nav-item v-on:click="toggleTheme">
          <font-awesome-icon :icon="themeIcon" />
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <b-navbar
      type="dark"
      class="navbar-secondary"
      v-if="hasSecondaryNavbarItems"
    >
      <b-nav-form>
        <b-button
          v-for="item in secondaryNavbarItems"
          :key="item.key"
          :variant="!!item.variant ? item.variant : 'outline-secondary'"
          class="mr-2"
          @click="item.click"
          ><font-awesome-icon v-if="!!item.icon" :icon="item.icon"
        /></b-button>
      </b-nav-form>
    </b-navbar>
  </div>
</template>

<script>
import router from "../router";

export default {
  computed: {
    authenticated() {
      return this.$store.getters.authenticated;
    },
    hasSecondaryNavbarItems() {
      return this.secondaryNavbarItems.length > 0;
    },
    secondaryNavbarItems() {
      return this.$store.getters.secondaryNavbarItems;
    },
    themeIcon() {
      return this.$store.getters.theme === "default" ? "moon" : "sun";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(function () {
        router.push("/login");
      });
    },
    toggleTheme() {
      this.$store.dispatch("toggleTheme");
    },
  },
};
</script>
