const configuration = {
    url: process.env.VUE_APP_URL,
    accessUrl: process.env.VUE_APP_ACCESS_URL,
    environment: process.env.VUE_APP_ENVIRONMENT,

    oauth: {
        development: {
            //"orcid": "https://sandbox.orcid.org/oauth/authorize?client_id=APP-7YFZZE0J2GZ038IV&response_type=token&scope=openid&redirect_uri=http://localhost:6599/oauth/orcid{register}",
            "orcid": "https://sandbox.orcid.org/oauth/authorize?client_id=APP-7YFZZE0J2GZ038IV&response_type=code&scope=/authenticate&redirect_uri=http://localhost:6599/oauth/orcid{register}",
            "github": "https://github.com/login/oauth/authorize?client_id=Iv1.1c127eeb5e5f1a75&redirect_uri=http://localhost:6599/oauth/github{register}"
        },
        production: {

        }
    },

    debugging() {
        return this.environment.toLowerCase() === 'development';
    },

    getApiUrl(path) {
        return this.url + path;
    },

    getOAuthUrl(name, register) {
        var providers = this.oauth[this.environment];

        if (!providers) {
            throw new Error(`Could not find oauth providers for environment '${this.environment}'.`);
        }

        var url = providers[name];

        if (!url) {
            throw new Error(`Could not find oauth provider url for name '${name}'.`);
        }

        return url.replace('{register}', register ? "-register" : "");
    }
}

if (Object.freeze) {
    Object.freeze(configuration);
}

export default configuration;